<template>
  <yandex-map
    v-model="map"
    :settings="mapSettings"
    width="100%"
    height="500px"
  >
    <yandex-map-default-scheme-layer/>

    <yandex-map-controls :settings="{ position: 'left' }">
      <yandex-map-control>
        <el-button @click="zoomIn" :icon="Plus"/>
      </yandex-map-control>

      <yandex-map-control>
        <el-button @click="zoomOut" :icon="Minus"/>
      </yandex-map-control>
    </yandex-map-controls>

    <yandex-map-controls :settings="{ position: 'top right' }">
      <yandex-map-control>
        <el-button @click="() => newPolygonCoords = [[]]">Стереть новые координаты</el-button>
      </yandex-map-control>
    </yandex-map-controls>

    <yandex-map-controls :settings="{ position: 'top left' }">
      <yandex-map-control>
        <el-input
          v-model="search"
          placeholder="Адрес или объект"
          clearable
          @keyup.enter="doSearch"
        >
          <template #append>
            <el-button @click="doSearch" :icon="Search"/>
          </template>
        </el-input>
      </yandex-map-control>
    </yandex-map-controls>

    <yandex-map-default-features-layer/>

    <template v-if="features">
      <yandex-map-feature
        v-for="(feature, index) in features"
        :key="index"
        :settings="feature"
      />
    </template>

    <yandex-map-feature :settings="{
            geometry: {
                type: 'Polygon',
                coordinates: newPolygonCoords,
            },
            style: {
                stroke: [{ color: '#007afce6', width: 4 }],
            },
        }"/>

    <yandex-map-listener :settings="{ onClick: (_, e) => newPolygonCoords[0].push(e.coordinates)}"/>
  </yandex-map>
</template>

<script setup lang="ts">

import { computed, ref, shallowRef } from 'vue'
import { YandexMapSettings } from 'vue-yandex-maps/dist/components/YandexMap.vue'
import { YMap, YMapFeatureProps } from '@yandex/ymaps3-types'
import {
  YandexMap, YandexMapControl, YandexMapControls,
  YandexMapDefaultFeaturesLayer,
  YandexMapDefaultSchemeLayer,
  YandexMapFeature, YandexMapListener
} from 'vue-yandex-maps'
import { LngLat } from '@yandex/ymaps3-types/common/types/lng-lat'
import { Search, Plus, Minus } from '@element-plus/icons-vue'
import { Nullable } from '@/types/forms'

const props = withDefaults(
  defineProps<{
    settings?: Nullable<YandexMapSettings>,
    polygonsCoordinates?: LngLat[][][]
  }>(),
  {
    settings: () => ({}) as Nullable<YandexMapSettings>
  }
)

const defaultMapSettings = {
  location: {
    center: [37.668547, 55.730285],
    zoom: 10
  }
}
const map = shallowRef<null | YMap>(null)
const newPolygonCoords = ref<LngLat[][]>([[]])
const search = ref('')

const mapSettings = computed(() => ({
  ...defaultMapSettings,
  ...props.settings,
  location: {
    ...defaultMapSettings.location,
    ...(props.settings?.location || {})
  }
}))
const features = computed<YMapFeatureProps[] | null>(() => {
  if (!props.polygonsCoordinates) {
    return null
  }

  const result: YMapFeatureProps[] = []

  props.polygonsCoordinates.forEach(coords => {
    result.push({
      geometry: {
        type: 'Polygon',
        coordinates: coords
      },
      style: {
        stroke: [{
          color: '#006efc',
          width: 4
        }],
        fill: 'rgba(56, 56, 219, 0.5)'
      }
    })
  })

  return result
})

const doSearch = async () => {
  // eslint-disable-next-line no-undef
  const result = await ymaps3.search({
    text: search.value
  })
  const center = result[0]?.geometry?.coordinates

  if (center) {
    map.value?.setLocation({
      zoom: 17,
      center: center
    })
  }
}
const zoomIn = () => {
  map.value?.setLocation({
    zoom: map.value?.zoom + 1,
    duration: 200
  })
}
const zoomOut = () => {
  map.value?.setLocation({
    zoom: map.value?.zoom - 1,
    duration: 200
  })
}

defineExpose({
  map,
  newPolygonCoords
})

</script>

<style scoped lang="scss">
:deep(.__ymap_container) {
  .ymaps3x0--button {
    background-color: var(--el-bg-color);
    color: var(--el-text-color-regular);
  }
}
</style>
