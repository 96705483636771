<template>
  <el-button @click="$emit('close')">
    Закрыть
  </el-button>

  <el-form
    ref="formRef"
    label-position="top"
    :model="state.form"
    :rules="rules"
    v-loading="state.loading"
  >
    <h2 v-if="!isUpdateForm">Новая категория услуг</h2>
    <h2 v-else>Редактирование категории услуг {{ recordTitle }}</h2>

    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="title" label="Наименование" :error="state.errors.title">
          <el-input v-model="state.form.title"/>
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="order_type_id" label="Тип работ" :error="state.errors.order_type_id">
          <el-select
            v-model="state.form.order_type_id"
            filterable
            clearable
          >
            <el-option
              v-for="option in orderTypes"
              :key="option.id"
              :value="option.id"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item>
      <el-button
        type="success"
        @click="submitForm"
      >Сохранить
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue'
import { ElMessage, FormInstance, FormRules } from 'element-plus'
import { iBaseFormState, iLocationForm, iServiceCategoryForm } from '@/types/forms'
import {
  iOrderType,
  iServiceCategory
} from '@/types/models'
import { ValidationException } from '@/utils/exceptions'
import restServiceCategories from '@/api/rest/restServiceCategories'
import restOrderTypes from '@/api/rest/restOrderTypes'

type StateInterface = iBaseFormState<iServiceCategoryForm>

const emit = defineEmits(['close', 'save'])
const props = defineProps<{
  recordId?: number
}>()
const formRef = ref<FormInstance>()
const loadedModelForUpdate = ref<iServiceCategory | null>(null)
const isUpdateForm = !!props.recordId
const recordTitle = ref('')
const orderTypes = ref<Array<iOrderType>>([])

const state = reactive<StateInterface>({
  loading: false,
  form: {
    id: null,
    title: '',
    order_type_id: undefined
  },
  errors: {}
})

const rules = computed<FormRules<iServiceCategory>>(() => {
  const rules = {} as FormRules<iServiceCategory>

  const fields: Array<keyof iServiceCategory> = ['title', 'order_type_id']

  fields.forEach((field) => {
    rules[field] = [
      { required: true, trigger: 'blur', message: 'Обязательно' }
    ]
  })

  return rules
})

const loadForm = (recordId: number) => {
  state.loading = true

  restServiceCategories.getById(recordId)
    .then(response => {
      const responseModel = response.data
      loadedModelForUpdate.value = responseModel

      for (const keyTemp in state.form) {
        const key = keyTemp as keyof iLocationForm

        if (!(key in responseModel)) continue

        state.form[key] = responseModel[key]
      }

      recordTitle.value = responseModel.title
    })
    .catch(error => {
      ElMessage.error('Непредвиденная ошибка')
      console.error(error)
    })
    .finally(() => {
      state.loading = false
    })
}
const loadOrderTypes = () => restOrderTypes.get()
  .then(response => {
    orderTypes.value = response.data
  })
  .catch(error => {
    ElMessage.error('Непредвиденная ошибка')
    console.error(error)
  })
const submitForm = async () => {
  try {
    await formRef.value?.validate()
  } catch (e) {
    ElMessage({
      message: 'Не все поля заполнены',
      type: 'warning'
    })

    return
  }

  let isSuccess = true
  state.loading = true

  const form = { ...state.form }

  try {
    const response = await restServiceCategories.save(form)
    emit('save', response.data)
  } catch (e: unknown) {
    if (e instanceof ValidationException) {
      state.errors = { ...e.errors }
    } else {
      ElMessage.error('Непредвиденная ошибка')
    }
    isSuccess = false
  }

  if (isSuccess) {
    emit('close')
  }
  state.loading = false
}

onMounted(async () => {
  state.loading = true

  await loadOrderTypes()

  state.loading = false

  if (!props.recordId) return

  loadForm(props.recordId)
})

</script>
