<template>
  <el-button @click="$emit('close')">
    Закрыть
  </el-button>

  <el-form
    ref="formRef"
    label-position="top"
    :model="state.form"
    :rules="rules"
    v-loading="state.loading"
  >
    <h2 v-if="!isUpdateForm">Новый пользователь</h2>
    <h2 v-else>Редактирование пользователя {{ state.form.title }}</h2>

    <el-row :gutter="20">
      <el-col :span="4">
        <el-form-item prop="title" label="ФИО" :error="state.errors.title">
          <el-input v-model="state.form.title"/>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="login" label="Логин" :error="state.errors.login">
          <el-input v-model="state.form.login"/>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item prop="password" label="Пароль" :error="state.errors.password">
          <el-input v-model="state.form.password" :disabled="isUpdateForm"/>
        </el-form-item>
      </el-col>

      <el-col :span="3">
        <el-form-item prop="phone" label="Телефон" :error="state.errors.phone">
          <el-input v-mask data-maska="+7 (###) ###-##-##" v-model="state.form.phone"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="role_id" label="Роль" :error="state.errors.role_id">
          <el-select
            v-model="state.form.role_id"
            filterable
            clearable
          >
            <el-option
              v-for="option in roles"
              :key="option.id"
              :value="option.id"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="partner_id" label="Партнер" :error="state.errors.partner_id">
          <el-select
            v-model="state.form.partner_id"
            filterable
            clearable
          >
            <el-option
              v-for="option in partners"
              :key="option.id"
              :value="option.id"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="shop_id" label="Магазин" :error="state.errors.shop_id">
          <el-select
            v-model="state.form.shop_id"
            filterable
            clearable
          >
            <el-option
              v-for="option in shops"
              :key="option.id"
              :value="option.id"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="order_type_id" label="Тип работ" :error="state.errors.order_type_id">
          <el-select
            v-model="state.form.order_type_id"
            filterable
            clearable
          >
            <el-option
              v-for="option in orderTypes"
              :key="option.id"
              :value="option.id"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">

      <el-col :span="4">
        <el-form-item label="Сотрудник">
          <el-input :model-value="userTitle" disabled/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item>
      <el-button
        type="success"
        @click="submitForm"
      >Сохранить
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script setup lang="ts">
// PhpStorm тупит с vMaska, потому переименовал
import { vMaska as vMask } from 'maska'
import { computed, onMounted, reactive, ref } from 'vue'
import { ElMessage, FormInstance, FormRules } from 'element-plus'
import { iBaseFormState, iUserForm } from '@/types/forms'
import {
  iOrderType, iPartner, iRole, iShop, iUser
} from '@/types/models'
import { ValidationException } from '@/utils/exceptions'
import restRoles from '@/api/rest/restRoles'
import restPartners from '@/api/rest/restPartners'
import restShops from '@/api/rest/restShops'
import restOrderTypes from '@/api/rest/restOrderTypes'
import { useStore } from '@/store'
import restUsers from '@/api/rest/restUsers'

type StateInterface = iBaseFormState<iUserForm>

const store = useStore()
const emit = defineEmits(['close', 'save'])
const props = defineProps<{
  orderId?: number
}>()
const formRef = ref<FormInstance>()
const loadedModelForUpdate = ref<iUser | null>(null)
const isUpdateForm = !!props.orderId
const userTitle = computed(() => loadedModelForUpdate.value?.createdBy?.title || store.state.user?.title || '')
const roles = ref<Array<iRole>>([])
const partners = ref<Array<iPartner>>([])
const shops = ref<Array<iShop>>([])
const orderTypes = ref<Array<iOrderType>>([])

const state = reactive<StateInterface & {
  loading: boolean
  errors: object
}>({
  loading: false,
  form: {
    id: null,
    title: '',
    login: '',
    password: '',
    phone: '',
    role_id: null,
    partner_id: null,
    shop_id: shops.value.length === 1 ? shops.value[0].id : null,
    order_type_id: null
  },
  errors: {}
})

const rules = computed<FormRules<iUser>>(() => {
  const rules = {} as FormRules<iUser>

  const fields: Array<keyof iUser> = ['title', 'login']

  if (!isUpdateForm) {
    fields.push('password')
  }

  fields.forEach((field) => {
    rules[field] = [
      { required: true, trigger: 'blur', message: 'Обязательно' }
    ]
  })

  rules.role_id = [
    { required: true, trigger: 'change', message: 'Обязательно' }
  ]

  rules.phone = [
    { pattern: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/, trigger: 'blur', message: 'Укажите телефон полностью' }
  ]

  return rules
})

const loadRoles = () => restRoles.list()
  .then(response => {
    roles.value = response.data.items
    state.form.role_id = response.data.items.find(item => item)?.id || null
  })
  .catch(error => {
    ElMessage.error('Непредвиденная ошибка')
    console.error(error)
  })
const loadPartners = () => restPartners.list()
  .then(response => {
    partners.value = response.data.items
  })
  .catch(error => {
    ElMessage.error('Непредвиденная ошибка')
    console.error(error)
  })
const loadShops = () => restShops.list()
  .then(response => {
    shops.value = response.data.items
  })
  .catch(error => {
    ElMessage.error('Непредвиденная ошибка')
    console.error(error)
  })
const loadOrderTypes = () => restOrderTypes.get()
  .then(response => {
    orderTypes.value = response.data
  })
  .catch(error => {
    ElMessage.error('Непредвиденная ошибка')
    console.error(error)
  })
const submitForm = async () => {
  try {
    await formRef.value?.validate()
  } catch (e) {
    ElMessage({
      message: 'Не все поля заполнены',
      type: 'warning'
    })

    return
  }

  let isSuccess = true
  state.loading = true

  const user = { ...state.form }

  try {
    const response = await restUsers.save(user)
    emit('save', response.data)
  } catch (e: unknown) {
    if (e instanceof ValidationException) {
      state.errors = { ...e.errors }
    } else {
      ElMessage.error('Непредвиденная ошибка')
    }
    isSuccess = false
  }

  if (isSuccess) {
    emit('close')
  }
  state.loading = false
}
const loadForm = (recordId: number) => {
  state.loading = true

  const requestParams = {
    params: {
      expand: ['shop', 'createdBy'].join(',')
    }
  }

  restUsers.getById(recordId, requestParams)
    .then(response => {
      const responseUser = response.data
      loadedModelForUpdate.value = responseUser

      for (const keyTemp in state.form) {
        const key = keyTemp as keyof iUserForm

        if (!(key in responseUser) || !responseUser[key]) continue

        state.form[key] = responseUser[key]
      }
      state.form.shop_id = responseUser.shop?.id || null
    })
    .catch(error => {
      ElMessage.error('Непредвиденная ошибка')
      console.error(error)
    })
    .finally(() => {
      state.loading = false
    })
}

onMounted(async () => {
  state.loading = true

  await loadRoles()
  await loadPartners()
  await loadShops()
  await loadOrderTypes()

  state.loading = false

  if (!props.orderId) return

  loadForm(props.orderId)
})

</script>
