import { AxiosPromise } from 'axios'
import { iValidationErrorResponse } from '@/types/api'
import { ErrorException, ValidationException } from '@/utils/exceptions'
import { ElNotification } from 'element-plus'

export default async <T> (request: AxiosPromise<T>, errorsState?: { errors: iValidationErrorResponse }): Promise<T|undefined> => {
  if (errorsState) {
    errorsState.errors = {}
  }
  try {
    const response = await request
    return response.data
  } catch (e: unknown) {
    if (e instanceof ValidationException) {
      if (errorsState) {
        errorsState.errors = e.errors
      }
    } else if (e instanceof ErrorException) {
      ElNotification({
        title: 'Ошибка',
        message: e.message,
        type: 'error'
      })
    } else {
      console.error(e)
    }
    return undefined
  }
}
