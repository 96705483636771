<template>
  <el-table :data="tableData" empty-text="Пусто">
    <el-table-column prop="call_date" label="Дата и время обзвона" />
    <el-table-column prop="rating" label="Общая оценка" />
    <el-table-column prop="is_time" label="Ссылка?" />
    <el-table-column prop="is_quality" label="Качество?" />
    <el-table-column prop="is_polite" label="Вежливость?" />
    <el-table-column prop="is_extra_work" label="Предложение доп работ?" />
    <el-table-column prop="extra_works" label="Список доп работ">
      <template #default="scope">
        <pre>{{ scope.row.extra_works }}</pre>
      </template>
    </el-table-column>
    <el-table-column prop="extra_price" label="Сумма доп работ" />
    <el-table-column prop="note" label="Комментарии">
      <template #default="scope">
        <pre>{{ scope.row.note }}</pre>
      </template>
    </el-table-column>
    <el-table-column prop="creator" label="Сотрудник" />
    <el-table-column width="150" v-if="canUpdate || canDelete">
      <template #default="scope">
        <el-button v-if="canUpdate" :icon="Edit" @click="emit('update', scope.row.item)" type="info" size="small" circle />
        <el-popconfirm
          v-if="canDelete"
          title="Удалить запись безвозвратно?"
          :width="220"
          @confirm="emit('delete', scope.row.item)"
        >
          <template #reference>
            <el-button :icon="Delete" type="danger" size="small" circle />
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
</template>

<script lang="ts" setup>
import { iOrderCallView } from '@/types/models'
import { computed } from 'vue'
import { dateTimeDecode, formatBool, formatCurrency } from '@/utils/format'
import { Edit, Delete } from '@element-plus/icons-vue'
import { useStore } from '@/store'

const props = defineProps<{
  calls: iOrderCallView[]
}>()

const emit = defineEmits(['update', 'delete'])

const store = useStore()

const canUpdate = computed(() => store.getters.can('call_update'))
const canDelete = computed(() => store.getters.can('call_delete'))

const tableData = computed(() => props.calls.map(item => ({
  item,
  id: item.id,
  creator: item.creator,
  call_date: dateTimeDecode(item.call_date),
  rating: item.rating,
  is_time: formatBool(item.is_time),
  is_quality: formatBool(item.is_quality),
  is_polite: formatBool(item.is_polite),
  is_extra_work: formatBool(item.is_extra_work),
  extra_works: item.extra_works,
  extra_price: formatCurrency(item.extra_price),
  note: item.note,
  created_at: dateTimeDecode(item.created_at),
  updated_at: dateTimeDecode(item.updated_at)
})))

</script>
