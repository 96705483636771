import instance from '@/api/rest/instance'
import { AxiosPromise, AxiosRequestConfig } from 'axios'
import { iFile, iMaster } from '@/types/models'
import { iRestCollection, iRestCollectionList } from '@/types/responses'
import { iMasterForm, Nullable } from '@/types/forms'

export const masterUrls = {
  savePassport: (masterId: number) => 'masters/' + masterId + '/passport'
}

export default {
  get: (config: AxiosRequestConfig = {}): AxiosPromise<iRestCollection<iMaster>> => instance.get('masters', config),
  list: (params = {}): AxiosPromise<iRestCollectionList<iMaster>> => instance.get('masters/list', params),
  getById: (id: number, params = {}): AxiosPromise<iMaster> => instance.get('masters/' + id, params),
  save: (params: Nullable<iMasterForm>): AxiosPromise<iMaster> => {
    if (params.id) return instance.put('masters/' + params.id, params)
    else return instance.post('masters', params)
  },
  passport: {
    get: (masterId: number, params = {}): AxiosPromise<iRestCollection<iFile>> => instance.get('masters/' + masterId + '/passport', params),
    delete: (masterId: number, id: number, params = {}) => instance.delete('masters/' + masterId + '/passport/' + id, params)
  },
  weekends: (config: AxiosRequestConfig = {}): AxiosPromise<iRestCollection<iMaster>> => instance.get('masters/weekends', config)
}
