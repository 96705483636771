import { iCreatedAtObject, iDateChunk } from '@/types/models'
import moment from 'moment/moment'

export default <T extends iCreatedAtObject> (data: T[]): Array<iDateChunk<T>> => {
  const blocks: Record<string, T[]> = {}
  console.log(data)
  data.forEach(item => {
    const date = moment(item.created_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
    if (blocks[date] as unknown === undefined) {
      blocks[date] = []
    }
    blocks[date]?.push(item)
  })

  const today = moment().format('YYYY-MM-DD')
  const yesterday = moment().subtract(1, 'd').format('YYYY-MM-DD')

  return Object.keys(blocks).map<iDateChunk<T>>(key => {
    const date = moment(key, 'YYYY-MM-DD')
    return {
      date: date.toDate(),
      title: key === today
        ? 'Сегодня'
        : (key === yesterday ? 'Вчера' : date.format('DD MMM')),
      items: blocks[key]
    }
  })
}
