export const orderFields = {
  id: 'ID',
  number: 'Номер',
  customer: 'Клиент',
  address: 'Адрес',
  notes: 'Примечания',
  inner_notes: 'Вн. примечания',
  cost: 'Сумма',
  type_id: 'Тип работ',
  category_id: 'Категория работ',
  date_order: 'Дата заказа',
  date_assemble: 'Дата сборки',
  date_expected: 'Дата ориент',
  date_call: 'Дата созвона',
  shop_id: 'Магазин',
  is_urgent: 'Срочность?',
  is_complaint: 'Жалоба?',
  is_refused: 'Отказ?',
  is_attention: 'Внимание?',
  salesman: 'Продавец',
  payment_part_shop: 'Сумма магазину',
  payment_part_master: 'Сумма мастеру осн',
  payment_part_master_extra: 'Сумма мастеру доп',
  payment_part_extra: 'Сумма доп работ',
  payment_date_shop: 'Дата опл магазину',
  payment_date_master: 'Дата опл мастеру',
  partner_id: 'Партнёр',
  master_id: 'Мастер',
  pre_master_id: 'Мастер предварительно',
  created_at: 'Дата создания',
  created_by_id: 'Создатель',
  updated_at: 'Обновлено',
  company_debt: 'Компания должна',
  master_debt: 'Долг мастера',
  call_is_time: 'Обзвон ссылка', // Кол центр
  call_is_satisfy: 'Обзвон оценка', // Кол центр
  call_notes: 'Обзвон комментарии', // Кол центр
  services: 'Услуги', // Список услуг
  payment_info: 'Оплата',
  parent_id: 'Копия заказа',
  is_lying: 'Ложный выезд',
  ignore_expected_date: 'Б/Д',
  kitchen_type: 'Тип кухни',
  refund_date: 'Дата возврата',
  is_refund: 'Возврат',
  paid_date: 'Дата оплаты',
  is_payment_immediately: 'Оплата на месте',
  is_paid: 'Оплачено',
  status_id: 'Статус',
  has_debt: 'Долг по основной сумме',
  has_debt_extra: 'Долг по доп. сумме',
  is_doc_passed: 'Документы сданы',
  doc_passed_date: 'Дата сдачи документов',
  files: 'Файлы'
}

export type iOrderField = keyof typeof orderFields

export const sortableFields: iOrderField[] = [
  'id',
  'created_by_id',
  'paid_date',
  'parent_id'
]
