<template>
  <span class="history-data">
    <span v-for="item in result" :key="item.title" class="history-data-item" :class="{ changed: item.changed }">
      <span class="title">{{ item.title}}:</span>
      {{ item.value }}
    </span>
  </span>
</template>

<script lang="ts" setup>

import { computed } from 'vue'
import { orderFields } from '@/data/orderFields'

const props = defineProps<{
  dataOld: Record<string, unknown>,
  dataNew: Record<string, unknown>
}>()

const result = computed(() => {
  const fieldsMap = orderFields
  if (props.dataOld && Object.keys(props.dataOld).length) {
    return Object.keys(props.dataNew).map(attribute => {
      return {
        changed: props.dataNew[attribute] !== props.dataOld[attribute],
        title: fieldsMap[attribute as keyof typeof fieldsMap] || attribute,
        value: props.dataNew[attribute]
      }
    })
  }
  return Object.keys(props.dataNew).map(attribute => {
    return {
      changed: false,
      title: fieldsMap[attribute as keyof typeof fieldsMap] || attribute,
      value: props.dataNew[attribute]
    }
  })
})

</script>

<style lang="scss">
.history-data {
  .history-data-item {
    display: block;
  }

  .title {
    font-weight: bold;
  }

  .changed {
    color: #f00;
  }
}
</style>
