<template>
  <div v-loading="loading" class="history-data-item">
    {{ result }}
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useStore } from '@/store'
import restMasters from '@/api/rest/restMasters'
import { iListItem } from '@/types/models'
import { orderFields } from '@/data/orderFields'

const props = defineProps<{
  attribute: string
  value: string
}>()

const result = ref()
const store = useStore()
const loading = ref<boolean>(true)

const makeText = async () => {
  if (props.attribute === 'master_id') {
    if (!store.getters.can('history_view_master')) {
      return 'Назначен мастер'
    } else {
      try {
        const response = await restMasters.getById(props.value as unknown as number)
        return 'Мастер: ' + response.data.title
      } catch (e) {
        console.error(e)
        return 'Мастер: ' + props.value
      }
    }
  }

  if (props.attribute === 'pre_master_id') {
    if (store.state.user.isAdmin) {
      try {
        const response = await restMasters.getById(props.value as unknown as number)
        return 'Мастер предварительно: ' + response.data.title
      } catch (e) {
        console.error(e)
        return 'Мастер предварительно: ' + props.value
      }
    } else {
      return 'Назначен мастер предварительно'
    }
  }

  if (props.attribute === 'status_id') {
    return 'Статус: ' + store.getters.statuses.find((item: iListItem) => item.id === props.value as unknown as number).title
  }

  return (orderFields[props.attribute as keyof typeof orderFields] || props.attribute) + ': ' + props.value
}

makeText().then(value => {
  result.value = value
  loading.value = false
})
</script>
