<template>
  <el-switch
    v-model="switchValue"
    size="large"
    :active-icon="Sunny"
    :inactive-icon="Moon"
  />
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { Sunny, Moon } from '@element-plus/icons-vue'

type iTheme = 'dark' | 'light'

const switchState = ref<iTheme>(localStorage.getItem('theme') as iTheme || 'dark')

const switchValue = computed({
  get () {
    return switchState.value === 'light'
  },
  set (value: boolean) {
    const theme = value ? 'light' : 'dark'
    switchState.value = theme
    localStorage.setItem('theme', theme)
    if (theme === 'light') {
      document.documentElement.classList.remove('dark')
      document.documentElement.classList.add('light')
    } else {
      document.documentElement.classList.remove('light')
      document.documentElement.classList.add('dark')
    }
  }
})

</script>
