import instance from '@/api/rest/instance'
import { AxiosPromise, AxiosRequestConfig } from 'axios'
import { iWeekend } from '@/types/models'

export default {
  save: (data: iWeekend): AxiosPromise<iWeekend> => {
    return instance.post('masters-weekends', data)
  },
  delete: (masterId: number, date: string, config: AxiosRequestConfig = {}) => instance.delete('masters-weekends/' + [masterId, date].join(','), config)
}
