<template>
  <el-row :gutter="20" v-loading="formRequest.state.pending">
    <el-col :span="12">

      <h2>Новая заявка</h2>

      <order-form-common ref="formCommonRef" :errors="formRequest.state.errors" @change-shop-id="handleShopChange" />

      <order-sub-form
        v-if="withoutServices"
        ref="subformRef"
        :number="state.order?.number"
        :order-type="orderType"
        :address="address"
        :is-update-form="true"
        :expected-number="state.shopSettings.expectedNumber"
        :services="null"
        :shop-id="state.order?.shop_id"
        :category="undefined"
        :errors="formRequest.state.errors"
      />

      <order-splitter-form
        v-else-if="state.services.length && shopId"
        ref="splitterRef"
        :shop-id="shopId"
        :services="state.services"
        :items="state.orderServices"
        :number="number"
        :expected-number="state.shopSettings.expectedNumber"
        :address="address"
      />

      <el-form-item>
        <el-button type="success" @click="submitForm">Сохранить</el-button>
      </el-form-item>

    </el-col>

    <el-col :span="12">
      <div v-loading="requestServices.state.pending">
        <h2>Услуги</h2>
        <el-checkbox label="Без услуг" v-model="withoutServices" />

        <el-select v-if="withoutServices" placeholder="Тип" v-model="orderTypeId">
          <el-option v-for="type in types" :key="type.id" :value="type.id" :label="type.title" />
        </el-select>

        <service-table
          v-if="!withoutServices"
          v-model="state.orderServices"
          :shop-id="shopId"
          :services="state.services"
        />
      </div>
    </el-col>
  </el-row>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import {
  iAddress,
  iOrderService,
  iService, iShopSettings
} from '@/types/models'
import OrderFormCommon from '@/components/orders/OrderFormCommon.vue'
import useDataRequest from '@/composables/useDataRequest'
import OrderSplitterForm from '@/components/orders/OrderSplitterForm.vue'
import { loadServicesByShopId } from '@/api/services'
import { getShopSettings, saveOrder } from '@/api/orders'
import ServiceTable from '@/components/orders/ServiceTable.vue'
import useFormRequest from '@/composables/useFormRequest'
import OrderSubForm from '@/components/orders/OrderSubForm.vue'
import { ElMessage, FormInstance } from 'element-plus'
import router from '@/router'
import { useStore } from '@/store'

interface StateInterface {
  services: Array<iService>
  orderServices: Array<iOrderService>
  subForms: Array<undefined>
  shopSettings: iShopSettings
}

const formCommonRef = ref()
const splitterRef = ref()
const subformRef = ref()
const request = useDataRequest()
const formRequest = useFormRequest()
const withoutServices = ref()
const store = useStore()

const number = computed(() => formCommonRef.value?.formData.number || '')
const shopId = computed(() => formCommonRef.value?.formData.shop_id)

const address = computed<iAddress>(() => formCommonRef.value?.address)
const requestServices = useDataRequest()

const orderTypeId = ref<number>()
const orderType = computed(() => store.state.orderTypes.find(type => type.id === orderTypeId.value))
const types = computed(() => store.state.orderTypes)

const state = reactive<StateInterface>({
  shopSettings: {
    expectedNumber: null
  },
  subForms: [],
  services: [],
  orderServices: []
})

const submitForm = async () => {
  if (withoutServices.value) {
    let fail = false

    try {
      await formCommonRef.value.formRef.validate()
      await subformRef.value.formRef.validate()
    } catch (e) {
      fail = true
    }

    if (fail) {
      ElMessage({
        message: 'Не все поля заполнены',
        type: 'warning'
      })
    } else {
      const success = await formRequest.tryRequest(async () => {
        await saveOrder({
          ...formCommonRef.value.formData,
          ...subformRef.value.formData,
          type_id: orderTypeId.value,
          services: state.orderServices
        })
      })

      if (success) {
        router.push({
          name: 'orders'
        })
      } else {
        ElMessage({
          message: 'Поля заполнены неправильно',
          type: 'warning'
        })
      }
    }
  } else {
    if (!state.orderServices.length) {
      return
    }

    const subForms = splitterRef.value.subFormRefs.map((i: typeof OrderSubForm) => i.formRef as unknown as FormInstance)
    const commonForm: FormInstance = formCommonRef.value.formRef

    let fail = false
    try {
      // @todo сделать по нормальному а так же в редактировании этот же момент
      await commonForm.validate()
      for (const subForm of subForms) {
        await subForm.validate()
      }
    } catch (e) {
      fail = true
    }

    if (fail) {
      ElMessage({
        message: 'Не все поля заполнены',
        type: 'warning'
      })
    } else {
      for (const subFormRef of splitterRef.value.subFormRefs) {
        const errors = []
        const success = await formRequest.tryRequest(async () => {
          await saveOrder({
            ...formCommonRef.value.formData,
            ...subFormRef.formData,
            services: state.orderServices
          })
        })

        errors.push(success ? [] : { ...formRequest.state.errors })

        if (!success) {
          fail = true
        }
      }

      if (fail) {
        ElMessage({
          message: 'Поля заполнены неправильно',
          type: 'warning'
        })
      } else {
        router.push({
          name: 'orders'
        })
      }
    }
  }
}

const handleShopChange = async (shopId: number | null) => {
  state.services = []
  state.orderServices = []

  if (shopId) {
    await requestServices.tryRequest(async () => {
      state.services = (await loadServicesByShopId({ shopId })).data || []
    })

    await request.tryRequest(async () => {
      state.shopSettings = (await getShopSettings(shopId)).data || {
        expectedNumber: null
      }
    })
  }
}

</script>
