<template>
  <el-table :data="tableData" show-summary :summary-method="getSummaries">
    <el-table-column prop="id" label="ID" />
    <el-table-column prop="title" label="Мастер" />
    <el-table-column prop="debt" label="Долг" />
    <el-table-column prop="bank" label="Банк" />
  </el-table>
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router'
import { computed, reactive, watch } from 'vue'
import { iDebt } from '@/types/models'
import useDataRequest from '@/composables/useDataRequest'
import { loadDebtors } from '@/api/debtors'
import { formatCurrency } from '@/utils/format'

const route = useRoute()
const state = reactive<{
  items: iDebt[]
}>({
  items: []
})
const request = useDataRequest()

const tableData = computed(() => {
  const items = [...state.items]
  items.sort((a, b) => b.debt - a.debt)
  items.map(item => ({
    id: item.id,
    title: item.title,
    bank: item.bank,
    debt: formatCurrency(item.debt)
  }))
  return items
})

const load = () => {
  request.tryRequest(async () => {
    const response = await loadDebtors(route.query)
    state.items = response.data
  })
}

const getSummaries = () => {
  return [
    'Итого',
    '',
    formatCurrency(state.items.reduce((carry, item) => carry + parseFloat(item.debt as unknown as string), 0)),
    ''
  ]
}

watch(() => route.query, load, { immediate: true })
</script>
