import instance from '@/api/rest/instance'
import { AxiosPromise } from 'axios'
import { iServiceCategory } from '@/types/models'
import { iRestCollection, iRestCollectionList } from '@/types/responses'
import { iServiceCategoryForm, Nullable } from '@/types/forms'

export default {
  get: (params = {}): AxiosPromise<iRestCollection<iServiceCategory>> => instance.get('categories', params),
  list: (params = {}): AxiosPromise<iRestCollectionList<iServiceCategory>> => instance.get('categories/list', params),
  getById: (id: number, params = {}): AxiosPromise<iServiceCategory> => instance.get('categories/' + id, params),
  save: (params: Nullable<iServiceCategoryForm>) => {
    if (params.id) return instance.put('categories/' + params.id, params)
    else return instance.post('categories', params)
  }
}
