<template>
  <el-button @click="$emit('close')">
    Закрыть
  </el-button>

  <el-form
    ref="formRef"
    label-position="top"
    :model="state.form"
    :rules="rules"
    v-loading="state.loading"
  >
    <h2 v-if="!isUpdateForm">Новая локация</h2>
    <h2 v-else>Редактирование локации {{ recordTitle }}</h2>

    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="title" label="Наименование" :error="state.errors.title">
          <el-input v-model="state.form.title"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item>
      <el-button
        type="success"
        @click="submitForm"
      >Сохранить
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue'
import { ElMessage, FormInstance, FormRules } from 'element-plus'
import { iBaseFormState, iLocationForm } from '@/types/forms'
import {
  iLocation
} from '@/types/models'
import { ValidationException } from '@/utils/exceptions'
import restLocations from '@/api/rest/restLocations'

type StateInterface = iBaseFormState<iLocationForm>

const emit = defineEmits(['close', 'save'])
const props = defineProps<{
  recordId?: number
}>()
const formRef = ref<FormInstance>()
const isUpdateForm = !!props.recordId
const recordTitle = ref('')

const state = reactive<StateInterface>({
  loading: false,
  form: {
    id: null,
    title: ''
  },
  errors: {}
})

const rules = computed<FormRules<iLocation>>(() => {
  const rules = {} as FormRules<iLocation>

  const fields: Array<keyof iLocation> = ['title']

  fields.forEach((field) => {
    rules[field] = [
      { required: true, trigger: 'blur', message: 'Обязательно' }
    ]
  })

  return rules
})

const loadForm = (recordId: number) => {
  state.loading = true

  restLocations.getById(recordId)
    .then(response => {
      const responseModel = response.data

      for (const keyTemp in state.form) {
        const key = keyTemp as keyof iLocationForm

        if (!(key in responseModel)) continue

        state.form[key] = responseModel[key]
      }

      recordTitle.value = responseModel.title
    })
    .catch(error => {
      ElMessage.error('Непредвиденная ошибка')
      console.error(error)
    })
    .finally(() => {
      state.loading = false
    })
}
const submitForm = async () => {
  try {
    await formRef.value?.validate()
  } catch (e) {
    ElMessage({
      message: 'Не все поля заполнены',
      type: 'warning'
    })

    return
  }

  let isSuccess = true
  state.loading = true

  const form = { ...state.form }

  try {
    const response = await restLocations.save(form)
    emit('save', response.data)
  } catch (e: unknown) {
    if (e instanceof ValidationException) {
      state.errors = { ...e.errors }
    } else {
      ElMessage.error('Непредвиденная ошибка')
    }
    isSuccess = false
  }

  if (isSuccess) {
    emit('close')
  }
  state.loading = false
}

onMounted(async () => {
  if (!props.recordId) return

  loadForm(props.recordId)
})

</script>
