<template>
  <order-sub-form
    v-for="(block, index) in blocks"
    :ref="el => setSubFormRef(el, index)"
    :key="index"
    :index="index"
    :services="block.services"
    :number="props.number"
    :expectedNumber="props.expectedNumber"
    :order-type="block.orderType"
    :category="block.category"
    :shop-id="props.shopId"
    :is-update-form="false"
    :address="props.address"
    :errors="errors[index] ?? {}"
  />
</template>

<script setup lang="ts">
import { iCategory, iOrderType, iService, iOrderService, iAddress } from '@/types/models'
import { computed, ref } from 'vue'
import { TableServiceType } from '@/composables/useServicesTable'
import { useStore } from '@/store'
import OrderSubForm from '@/components/orders/OrderSubForm.vue'
import { iFormErrors } from '@/types/responses'
import { iOrderBlockForm } from '@/types/forms'

const props = defineProps<{
  number: string,
  shopId: number|undefined|null,
  expectedNumber: number|null,
  services: Array<iService>,
  items: Array<iOrderService>
  address: iAddress
}>()

interface iBlockItem {
  category: iCategory
  orderType: iOrderType
  services: Array<TableServiceType>
}

const subFormRefs = ref<HTMLElement[]>([])
const setSubFormRef = (el: HTMLElement | null, index: number) => {
  if (el) {
    subFormRefs.value[index] = el
  }
}

const errors = ref<iFormErrors<iOrderBlockForm>[]>([])

const setErrors = (newErrors: iFormErrors<iOrderBlockForm>, index: number) => {
  const err = [...errors.value]
  err[index] = newErrors
  errors.value = err
}

defineExpose({
  subFormRefs,
  setErrors
})

const store = useStore()

const blocks = computed(() => {
  const services = props.items.map<iOrderService>(item => ({
    ...(props.services.find(s => s.id === item.service_id) || {}),
    ...item
  })) as Array<TableServiceType>

  const map: Record<string, iBlockItem> = {}

  services.filter(s => !s.is_additional).forEach(service => {
    const key = service.order_type_id + ':' + service.category_id
    const orderType = store.state.orderTypes.find(i => i.id === service.order_type_id)
    const list = map[key] || {
      category: orderType?.categories.find(i => i.id === service.category_id),
      orderType: orderType,
      services: []
    }
    list.services.push(service)
    map[key] = list
  })

  services.filter(s => s.is_additional).forEach(service => {
    const key = service.order_type_id + ':' + service.category_id
    const orderType = store.state.orderTypes.find(i => i.id === service.order_type_id)
    const list = map[key] || {
      category: orderType?.categories.find(i => i.id === service.category_id),
      orderType: orderType,
      services: []
    }
    list.services.push(service)
    map[key] = list
  })

  return Object.values(map)
})

</script>
