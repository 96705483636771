<template>
  <div class="history-day">
    <el-tag type="primary">{{ props.title }}</el-tag>

    <el-table :data="props.items" :show-header="false">
      <el-table-column width="150">
        <template #default="scope">
          {{ formatTime(scope.row.created_at) }}
          <br>({{ scope.row.user.title }})
        </template>
      </el-table-column>
      <el-table-column width="300">
        <template #default="scope">
          {{ scope.row.title }}
        </template>
      </el-table-column>
      <el-table-column>
        <template #default="scope">
          <dashboard-history-item-data v-if="scope.row.data_old" :data-new="scope.row.data" :data-old="scope.row.data_old" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts" setup>
import { iHistory } from '@/types/models'
import { formatTime } from '@/utils/format'
import DashboardHistoryItemData from '@/components/dashboard/DashboardHistoryItemData.vue'

const props = defineProps<{
  title: string,
  items: iHistory[]
}>()
</script>

<style lang="scss">
.history-day {
  margin: 10px 0;
}
</style>
