<template>
  <el-table v-loading="state.loading" :data="state.roles">
    <el-table-column prop="id" label="ID" />
    <el-table-column prop="title" label="Название" />
    <el-table-column>
      <template #default="scope">
        <el-button v-if="scope.row.id > 1" type="primary" @click="emit('select', scope.row)">Разрешения</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script lang="ts" setup>
import { onMounted, reactive } from 'vue'
import { iRole } from '@/types/models'
import { loadRoles } from '@/api/roles'

const state = reactive<{
  roles: iRole[]
  loading: boolean
}>({
  roles: [],
  loading: true
})

const emit = defineEmits(['select'])

const load = () => {
  loadRoles().then(response => {
    state.roles = response.data
    state.loading = false
  })
}

onMounted(load)

defineExpose({
  load
})
</script>
