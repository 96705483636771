<template>
  <button class="statistic-card" :class="[props.type]" @click="emit('select', props.type)">
    <div class="statistic-icon">
      <el-icon>
        <document-add v-if="props.type === 'created'" />
        <circle-check v-else-if="props.type === 'completed'" />
        <calendar v-else-if="props.type === 'assemble'" />
        <refresh v-else-if="props.type === 'statuses'" />
        <avatar v-else-if="props.type === 'masters'" />
      </el-icon>
    </div>
    <div class="statistic-body">
      <div class="statistic-card-title">{{ titles[props.type] }}</div>
      <div class="statistic-value">{{ props.item.count }}</div>
      <div class="statistic-summary">{{ summary }}</div>
    </div>
  </button>
</template>

<script lang="ts" setup>
import { iStatisticData, iStatisticDataItem, iStatisticDataItemWithSummary } from '@/types/models'
import { DocumentAdd, CircleCheck, Refresh, Avatar, Calendar } from '@element-plus/icons-vue'
import { computed } from 'vue'
import { formatCurrency } from '@/utils/format'

const props = defineProps<{
  type: keyof iStatisticData
  item: iStatisticDataItemWithSummary | iStatisticDataItem
}>()

const titles: Record<keyof iStatisticData, string> = {
  created: 'Создано заказов',
  completed: 'Выполнено заказов',
  statuses: 'Изменено статусов',
  masters: 'Назначено мастеров',
  assemble: 'Установлено ориент. дат сборки'
}

const emit = defineEmits(['select'])

const summary = computed(() => (props.item as Partial<iStatisticDataItemWithSummary>).summary !== undefined
  ? formatCurrency((props.item as iStatisticDataItemWithSummary).summary)
  : '')

</script>

<style lang="scss">
.statistic-card {
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  min-height: 140px;
  border: none;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
  text-align: left;

  &:hover {
    opacity: .8;
  }

  .statistic-body {
    flex-grow: 1;
  }

  .statistic-icon {
    font-size: 30px;
    margin-right: 20px;
  }

  &.created {
    background: rgba(51, 122, 183, .2);
    color: #337ab7;
  }

  &.completed {
    background: rgba(67, 58, 183, 0.2);
    color: #595db7;
  }

  &.statuses {
    background: rgba(240, 173, 78, .2);
    color: #f0ad4e;
  }

  &.masters {
    background: rgba(83, 88, 92, .2);
    color: #53585c;
  }

  &.assemble {
    background: rgba(92, 184, 92, .2);
    color: #5cb85c;
  }
}

.statistic-summary {
  padding: 3px 5px;
  border-radius: 2px;
  margin-top: 10px;
  display: inline-block;

  .created & {
    background: #337ab7;
    color: #fff;
  }

  .completed & {
    background: #595db7;
    color: #fff;
  }
}

.statistic-value {
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 28px;

  .danger & {
    color: var(--el-color-danger)
  }

  .warning & {
    color: var(--el-color-warning)
  }

  .success & {
    color: var(--el-color-success)
  }

  .count {
    margin-left: 20px;
  }
}
</style>
