<template>
  <div :hidden="formIsOpen">
    <el-button @click="showForm">
      Новый пользователь
    </el-button>
    <user-index ref="indexRef" @edit-record="handlerEditRecord" />
  </div>
  <user-form v-if="formIsOpen" :order-id="selectedRecordId" @close="hideForm" @save="handlerSave" />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import UserIndex from '@/components/users/UserIndex.vue'
import UserForm from '@/components/users/UserForm.vue'

const indexRef = ref<InstanceType<typeof UserIndex>>(null)

const handlerSave = () => {
  indexRef.value.load()
}
const handlerEditRecord = (recordId: number) => {
  selectedRecordId.value = recordId
  showForm()
}
const showForm = () => {
  formIsOpen.value = true
}
const hideForm = () => {
  formIsOpen.value = false
  selectedRecordId.value = undefined
}

const formIsOpen = ref<boolean>(false)
const selectedRecordId = ref<number|undefined>(undefined)
</script>
