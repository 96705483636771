import instance from '@/api/rest/instance'
import { AxiosPromise } from 'axios'
import { iLocation } from '@/types/models'
import { iRestCollection, iRestCollectionList } from '@/types/responses'
import { iLocationForm, Nullable } from '@/types/forms'

export default {
  get: (params = {}): AxiosPromise<iRestCollection<iLocation>> => instance.get('locations', params),
  list: (params = {}): AxiosPromise<iRestCollectionList<iLocation>> => instance.get('locations/list', params),
  getById: (id: number, params = {}): AxiosPromise<iLocation> => instance.get('locations/' + id, params),
  save: (params: Nullable<iLocationForm>) => {
    if (params.id) return instance.put('locations/' + params.id, params)
    else return instance.post('locations', params)
  }
}
