<template>
  <el-alert type="info" :closable="false">
    <slot/>
  </el-alert>
</template>

<script setup lang="ts">

</script>

<style scoped>
.el-alert {
  --el-alert-padding: 0 10px;
  --el-alert-description-font-size: 13px;
}
</style>
