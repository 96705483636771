import _ from 'lodash'

type iErrors = {
  [key:string]: string
}
export const normalizeRestErrors = (errors: iErrors) => {
  const result: {[key: string]: string|object} = {}

  Object.keys(errors).forEach((key: keyof iErrors) => _.set(result, key, errors[key]))

  return result
}
