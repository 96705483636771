<template>
  <div :hidden="formIsOpen">
    <el-button @click="showForm">
      Новый мастер
    </el-button>
    <master-index ref="indexRef" @edit-record="handlerEditRecord"/>
  </div>
  <master-form v-if="formIsOpen" :record-id="selectedRecordId" @close="hideForm" @save="handlerSave"/>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import LocationIndex from '@/components/locations/LocationIndex.vue'
import MasterIndex from '@/components/masters/MasterIndex.vue'
import MasterForm from '@/components/masters/MasterForm.vue'

const indexRef = ref<InstanceType<typeof LocationIndex>>(null)

const handlerSave = () => {
  indexRef.value.load()
}
const handlerEditRecord = (recordId: number) => {
  selectedRecordId.value = recordId
  showForm()
}
const showForm = () => {
  formIsOpen.value = true
}
const hideForm = () => {
  formIsOpen.value = false
  selectedRecordId.value = undefined
}

const formIsOpen = ref<boolean>(false)
const selectedRecordId = ref<number | undefined>(undefined)
</script>
