<template>
  <div
    v-for="chunk in chunks"
    :key="chunk.date"
    class="statistic-history-day"
  >
    <el-tag type="primary">{{ chunk.title }}</el-tag>

    <el-table :data="chunk.items" :show-header="false">
      <el-table-column width="100">
        <template #default="scope">
          {{ formatTime(scope.row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column width="250">
        <template #default="scope">
          Заказ
          <router-link :to="{name: 'order-update', params: { id: scope.row.id }}" v-slot="{ href, navigate }">
            <el-link :href="href" @click="navigate">{{ scope.row.number }}</el-link>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template #default="scope">
          <router-link :to="{ name: 'history', query: { order_id: scope.row.id, period: makeDateFilterValue([scope.row.created_at, scope.row.created_at]) } }" v-slot="{ href, navigate }">
            <el-link :href="href" @click="navigate">История</el-link>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts" setup>
import { iStatisticHistoryItem } from '@/types/models'
import { formatTime } from '@/utils/format'
import { computed } from 'vue'
import makeChunks from '@/utils/makeChunks'
import { makeDateFilterValue } from '@/utils/filter'

const props = defineProps<{
  items: iStatisticHistoryItem[]
}>()

const chunks = computed(() => makeChunks(props.items))
</script>
