<template>
  <div>
    <el-button type="primary" @click="showDialog = true">Добавить</el-button>
  </div>
  <roles-table ref="tableRef" @select="handleSelect" />
  <el-dialog v-model="showDialog" @close="handleClose" modal :close-on-click-modal="false">
    <role-form ref="roleFormRef" @save="handleSave" />
  </el-dialog>
</template>

<script lang="ts" setup>
import RolesTable from '@/components/roles/RolesTable.vue'
import { iRole } from '@/types/models'
import { nextTick, ref } from 'vue'
import RoleForm from '@/components/roles/RoleForm.vue'

const showDialog = ref(false)
const roleFormRef = ref()
const tableRef = ref()

const handleSelect = (role: iRole) => {
  showDialog.value = true
  nextTick(() => {
    roleFormRef.value?.load(role)
  })
}

const handleClose = () => {
  roleFormRef.value?.resetForm()
}

const handleSave = () => {
  showDialog.value = false
  tableRef.value?.load()
}
</script>
