<template>
  <el-form inline label-position="top" @submit.prevent="handleSubmit">
    <el-form-item label="Дата события">
      <el-date-picker
        v-model="filter.period"
        type="daterange"
        format="DD.MM.YYYY"
        :editable="false"
        :shortcuts="pickerShortcuts"
        :clearable="false"
        unlink-panels
      />
    </el-form-item>

    <el-form-item label="ID">
      <el-input v-model="filter.id" clearable style="width: 200px;" />
    </el-form-item>

    <el-form-item label="Номер">
      <el-input v-model="filter.number" clearable style="width: 200px;" />
    </el-form-item>

    <el-form-item label="&nbsp;">
      <el-button native-type="submit" type="success">Применить</el-button>
      <el-button v-if="isFiltered" type="warning" @click="handleReset">Сбросить</el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts" setup>

import pickerShortcuts from '@/utils/pickerShortcuts'
import { computed, watch, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { extractDateValue, makeDateFilterValue } from '@/utils/filter'

const route = useRoute()
const router = useRouter()

const isFiltered = computed(() => route.query.period !== undefined)

const filter = reactive<{
  period: Date[]
  id: string
  number: string
  model: string
}>({
  period: [new Date(), new Date()],
  id: '',
  number: '',
  model: ''
})

const handleReset = () => {
  router.push({ query: {} })
}

const handleSubmit = () => {
  router.push({
    query: {
      ...filter,
      period: makeDateFilterValue(filter.period)
    }
  })
}

watch(() => route.query, () => {
  const query = route.query
  filter.id = query.id as string || ''
  filter.number = query.number as string || ''
  filter.period = extractDateValue(query.period, [new Date(), new Date()]) as Date[]
}, { immediate: true })

</script>
