<template>
  <el-button @click="$emit('close')">
    Закрыть
  </el-button>

  <el-form
    ref="formRef"
    label-position="top"
    :model="state.form"
    :rules="rules"
    v-loading="state.loading"
  >

    <el-row :gutter="20">
      <el-col :span="8">
        <h2 v-if="!isUpdateForm">Новый партнер</h2>
        <h2 v-else>Редактирование партнера {{ recordTitle }}</h2>

        <el-form-item prop="title" label="Наименование" :error="state.errors.title">
          <el-input v-model="state.form.title"/>
        </el-form-item>
        <el-form-item prop="phone" label="Телефон" :error="state.errors.phone">
          <el-input v-mask data-maska="+7 (###) ###-##-##" v-model="state.form.phone"/>
        </el-form-item>

        <el-form-item>
          <el-button
            type="success"
            @click="submitForm"
          >Сохранить
          </el-button>
        </el-form-item>
      </el-col>
      <el-col :span="16">
        <partner-data-forms ref="partnerDataFormsRef" :data="state.form.data" :errors="state.errors.data" />
      </el-col>
    </el-row>
  </el-form>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, reactive, ref } from 'vue'
import { ElMessage, FormInstance, FormRules } from 'element-plus'
import { iNestedFormState, iPartnerForm } from '@/types/forms'
import {
  iPartner
} from '@/types/models'
import { ValidationException } from '@/utils/exceptions'
import restPartners from '@/api/rest/restPartners'
import PartnerDataForms from '@/components/partners/PartnerDataForms.vue'
import PartnerDataForm from '@/components/partners/PartnerDataForm.vue'
import { vMaska as vMask } from 'maska'
import { normalizeRestErrors } from '@/utils/restHelper'
import { iPartnerDataFormErrors, iPartnerFormErrors } from '@/types/formsErrors'

type StateInterface = iNestedFormState<iPartnerForm, iPartnerFormErrors>

const emit = defineEmits(['close', 'save'])
const props = defineProps<{
  recordId?: number
}>()

const formRef = ref<FormInstance>()
const loadedModelForUpdate = ref<iPartner | null>(null)
const recordTitle = ref('')
const partnerDataFormsRef = ref<InstanceType<typeof PartnerDataForms>>()

const isUpdateForm = !!props.recordId

const state = reactive<StateInterface>({
  loading: false,
  form: {
    id: null,
    title: '',
    phone: '',
    data: []
  },
  errors: {}
})

const rules = computed<FormRules<iPartner>>(() => {
  const rules = {} as FormRules<iPartner>

  const fields: Array<keyof iPartner> = ['title']

  fields.forEach((field) => {
    rules[field] = [
      { required: true, trigger: 'blur', message: 'Обязательно' }
    ]
  })

  rules.phone = [
    { required: true, trigger: 'blur', message: 'Обязательно' },
    { pattern: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/, trigger: 'blur', message: 'Укажите телефон полностью' }
  ]

  return rules
})

const loadForm = (recordId: number) => {
  state.loading = true
  const requestParams = {
    params: {
      expand: ['data'].join(',')
    }
  }

  restPartners.getById(recordId, requestParams)
    .then(response => {
      const responseModel = response.data
      loadedModelForUpdate.value = responseModel

      for (const keyTemp in state.form) {
        const key = keyTemp as keyof iPartnerForm

        if (!(key in responseModel)) continue

        state.form[key] = responseModel[key]
      }

      recordTitle.value = responseModel.title
    })
    .catch(error => {
      ElMessage.error('Непредвиденная ошибка')
      console.error(error)
    })
    .finally(() => {
      state.loading = false
    })
}
const submitForm = async () => {
  let failValidate = false
  const forms = partnerDataFormsRef.value.formRefs.map((item: InstanceType<typeof PartnerDataForm>) => item.formRef)

  forms.push(formRef.value)

  for (const form of forms) {
    try {
      await form.validate()
    } catch (e) {
      failValidate = true
    }
  }

  if (failValidate) {
    ElMessage({
      message: 'Ошибка валидации',
      type: 'warning'
    })
    return
  }

  let isSuccess = true
  state.loading = true

  const form = { ...state.form }
  form.data = []

  partnerDataFormsRef.value.formRefs.forEach((item: InstanceType<typeof PartnerDataForm>) => {
    const shopId = item.state.form.shop_id

    item.state.form.orderTypes.forEach((typeId: number) => {
      const foundNotChangeRecord = state.form.data?.find(itemData => itemData.shop_id === shopId && itemData.order_type_id === typeId && itemData.partner_id === state.form.id)

      if (foundNotChangeRecord) {
        form.data?.push(foundNotChangeRecord)
      } else {
        form.data?.push({
          shop_id: item.state.form.shop_id,
          order_type_id: typeId
        })
      }
    })
  })

  if (!form.data.length) {
    delete form.data
  }

  try {
    const response = await restPartners.save(form)
    emit('save', response.data)
  } catch (e: unknown) {
    if (e instanceof ValidationException) {
      const normErrors = normalizeRestErrors({ ...e.errors }) as iPartnerFormErrors

      if ('data' in normErrors) {
        const newDataErrors = {} as {[key: string]: iPartnerDataFormErrors}

        Object.keys(normErrors.data || {}).forEach(key => {
          if (normErrors.data && form.data) {
            newDataErrors[form.data[Number(key)].shop_id] = normErrors.data[key]
          }
        })

        normErrors.data = newDataErrors
      }

      state.errors = {}
      ElMessage.warning('Ошибка валидации')

      await nextTick(() => {
        state.errors = normErrors
      })
    } else {
      ElMessage.error('Непредвиденная ошибка')
    }
    isSuccess = false
  }

  if (isSuccess) {
    emit('close')
  }
  state.loading = false
}

onMounted(async () => {
  if (!props.recordId) return

  loadForm(props.recordId)
})

</script>
