<template>
  <div v-if="loading">
    <div class="loader-wrapper">
      <div class="loader"></div>
    </div>
  </div>
  <login-form v-else-if="isUnauthorized"/>
  <template v-else>
    <page-header />
    <main class="page-content-wrapper">
      <router-view />
    </main>
  </template>
</template>

<script setup lang="ts">
import LoginForm from '@/components/LoginForm.vue'
import PageHeader from '@/components/header/PageHeader.vue'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'

const store = useStore()

const isUnauthorized = computed(() => store.getters.isUnauthorized)

// Загрузка пользователя при старте приложения, если ранее был выполнен вход,
// выполняется только 1 раз при старте приложения
const loading = ref(true)

store.dispatch('loginByToken').then(() => {
  loading.value = false
})

</script>
