<template>
  <dashboard-history-item
    v-for="chunk in chunks"
    :key="chunk.date"
    :title="chunk.title"
    :items="chunk.items"
  />
</template>

<script lang="ts" setup>
import { computed, reactive, watch } from 'vue'
import requestWrapper from '@/utils/requestWrapper'
import { getHistory } from '@/api/dashboard'
import { iHistory } from '@/types/models'
import DashboardHistoryItem from '@/components/dashboard/DashboardHistoryItem.vue'
import makeChunks from '@/utils/makeChunks'

const props = defineProps<{
  number: string
}>()

const state = reactive<{
  data: iHistory[]
  loading: boolean
}>({
  data: [],
  loading: true
})

const load = () => {
  requestWrapper(getHistory(props.number)).then(response => {
    state.data = response
    state.loading = false
  })
}

const chunks = computed(() => makeChunks(state.data))

watch(() => props.number, () => {
  state.loading = true
  load()
}, { immediate: true })
</script>
