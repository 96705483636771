<template>
  <dashboard-history-item-data-attribute
    v-for="item in result"
    :key="item.attribute"
    :attribute="item.attribute"
    :value="item.value"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import DashboardHistoryItemDataAttribute from '@/components/dashboard/DashboardHistoryItemDataAttribute.vue'

const props = defineProps<{
  dataOld: Record<string, unknown>
  dataNew: Record<string, unknown>
}>()

const result = computed(() => {
  if (props.dataOld && Object.keys(props.dataOld).length) {
    return Object.keys(props.dataNew).map(attribute => {
      return {
        changed: props.dataNew[attribute] !== props.dataOld[attribute],
        attribute,
        value: props.dataNew[attribute]
      }
    }).filter(item => item.changed)
  }

  return []
})
</script>
