<template>
  <div v-loading="state.loading">
    <dashboard-chart v-if="state.data.length" :data="state.data" />
    <div class="no-result" v-else>Нет заявок</div>
  </div>
</template>

<script setup lang="ts">
import { reactive, watch, onUnmounted } from 'vue'
import { iChartItem } from '@/types/models'
import requestWrapper from '@/utils/requestWrapper'
import { getChartData } from '@/api/dashboard'
import { makeDateFilterValue } from '@/utils/filter'
import DashboardChart from '@/components/dashboard/DashboardChart.vue'

const props = defineProps<{
  shops: number[]
  period: string[]
  statuses: number[]
}>()

const state = reactive<{
  data: iChartItem[]
  loading: boolean
}>({
  data: [],
  loading: true
})

const load = () => {
  requestWrapper(getChartData({
    shops: props.shops.join(','),
    period: makeDateFilterValue(props.period),
    statuses: props.statuses.join(',')
  })).then(response => {
    state.data = response as unknown as iChartItem[]
    state.loading = false
  })
}

watch(() => [props.shops, props.statuses, props.period], () => {
  state.loading = true
  load()
}, { immediate: true })

const interval = setInterval(load, 10000)

onUnmounted(() => {
  clearInterval(interval)
})
</script>

<style lang="scss">
.no-result {
  padding: 40px 0;
  font-weight: bold;
}
</style>
