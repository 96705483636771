<template>
  <div v-loading="state.loading" class="login-wrapper">
    <div class="login-inner">
      <h4>Войдите в свой аккаунт</h4>
      <el-form
        :model="state.form"
        :rules="state.rules"
        ref="formRef"
        label-position="top"
        class="login-form"
        size="large"
      >
        <el-form-item label="Логин" :error="state.errors.login" prop="login">
          <el-input v-model="state.form.login" @keyup="e => onKeyPress(e, formRef)"></el-input>
        </el-form-item>
        <el-form-item label="Пароль" :error="state.errors.password" prop="password">
          <el-input v-model="state.form.password" type="password" @keyup="e => onKeyPress(e, formRef)"></el-input>
        </el-form-item>
        <el-form-item label="&nbsp;">
          <el-button type="primary" style="width: 100%;" @click="submitForm(formRef)">Войти</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'
import type { FormInstance } from 'element-plus'
import { userLogin } from '@/api/auth'
import { useStore } from '@/store'
import requestWrapper from '@/utils/requestWrapper'
import { iBaseFormState } from '@/types/forms'
import { iUserToken } from '@/types/responses'

const store = useStore()
const formRef = ref<FormInstance>()

interface FormInterface {
  password: string
  login: string
}

const state = reactive<iBaseFormState<FormInterface>>({
  loading: false,
  form: {
    password: '',
    login: ''
  },
  errors: {},
  rules: {
    login: [
      { required: true, trigger: 'blur', message: 'Обязательно для заполнения' }
    ],
    password: [
      { required: true, trigger: 'blur', message: 'Обязательно для заполнения' }
    ]
  }
})

const submitForm = async (formEl: FormInstance|undefined) => {
  if (formEl === undefined) {
    return
  }
  await formEl.validate(async (valid) => {
    if (valid) {
      state.loading = true
      await store.dispatch('loginByToken', (await requestWrapper<iUserToken>(userLogin(state.form as FormInterface), state))?.token)
      state.loading = false
    }
  })
}

const onKeyPress = (e: KeyboardEvent, formRef: FormInstance) => {
  if (e.key === 'Enter') {
    submitForm(formRef)
  }
}
</script>

<style lang="scss">
.login-wrapper {
  min-height: 100vh;
  padding: 125px 275px 0;
  box-sizing: border-box;

  .login-inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    .login-form {
      width: 50%;
    }
  }
}
</style>
