<template>
  <div v-loading="request.state.pending || formRequest.state.pending">
    <el-row :gutter="20">
      <el-col :span="12">
        <h2>Редактирование заявки №{{ state.order?.number }}</h2>

        <order-form-common ref="formCommonRef" :errors="formRequest.state.errors" />

        <order-sub-form
          ref="subformRef"
          :number="state.order?.number"
          :order-type="orderType"
          :address="address"
          :is-update-form="true"
          :expected-number="state.shopSettings.expectedNumber"
          :services="tableServices"
          :shop-id="state.order?.shop_id"
          :category="orderCategory"
          :errors="formRequest.state.errors"
        />

        <el-form-item>
          <el-button type="success" @click="submitForm">Сохранить</el-button>
        </el-form-item>

      </el-col>

      <el-col :span="12">
        <div>
          <h2>Услуги</h2>
          <service-table
            v-model="state.orderServices"
            :shop-id="state.order?.shop_id"
            :services="state.services"
            disable-type
            disable-category
            :order-type-id="state.order?.type_id"
            :category-id="state.order?.category_id"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import { ElMessage } from 'element-plus'
import {
  iAddress,
  iOrder, iOrderService,
  iService, iShopSettings
} from '@/types/models'
import OrderFormCommon from '@/components/orders/OrderFormCommon.vue'
import useDataRequest from '@/composables/useDataRequest'
import restOrders from '@/api/rest/restOrders'
import OrderSubForm from '@/components/orders/OrderSubForm.vue'
import { useStore } from '@/store'
import ServiceTable from '@/components/orders/ServiceTable.vue'
import { loadServicesByShopId } from '@/api/services'
import { saveOrder } from '@/api/orders'
import useFormRequest from '@/composables/useFormRequest'
import router from '@/router'

interface StateInterface {
  services: Array<iService>
  orderServices: Array<iOrderService>
  shopSettings: iShopSettings
  order: iOrder|undefined
}

const props = defineProps<{
  id: number
}>()

const formCommonRef = ref()
const subformRef = ref()
const request = useDataRequest()
const formRequest = useFormRequest()
const store = useStore()

const orderType = computed(() => state.order?.type_id ? store.state.orderTypes.find(type => type.id === state.order?.type_id) : undefined)
const orderCategory = computed(() => state.order?.category_id ? store.getters.categories[state.order.category_id] : undefined)

const address = computed<iAddress>(() => formCommonRef.value?.address)

const state = reactive<StateInterface>({
  shopSettings: {
    expectedNumber: null
  },
  services: [],
  order: undefined,
  orderServices: []
})

const submitForm = async () => {
  let fail = false

  try {
    await formCommonRef.value.formRef.validate()
    await subformRef.value.formRef.validate()
  } catch (e) {
    fail = true
  }

  if (fail) {
    ElMessage({
      message: 'Не все поля заполнены',
      type: 'warning'
    })
  } else {
    const success = await formRequest.tryRequest(async () => {
      await saveOrder({
        ...formCommonRef.value.formData,
        ...subformRef.value.formData,
        id: props.id,
        services: state.orderServices
      })
    })

    if (success) {
      router.push({
        name: 'orders'
      })
    } else {
      ElMessage({
        message: 'Поля заполнены неправильно',
        type: 'warning'
      })
    }
  }
}

const tableServices = computed(() => state.orderServices.map<Partial<iService> & iOrderService>((item) => {
  const service = state.services.find(i => i.id === item.service_id)
  return {
    ...service,
    ...item
  }
}))

const loadOrder = async (orderId: number) => {
  const requestParams = {
    params: {
      expand: ['services'].join(',')
    }
  }

  await request.tryRequest(async () => {
    const response = (await restOrders.getById(orderId, requestParams)).data
    state.order = undefined

    formCommonRef.value.assign(response)
    state.orderServices = response.services

    state.services = (await loadServicesByShopId({ shopId: response.shop_id })).data

    // Подгружаем в последнюю очередь
    state.order = response

    subformRef.value.assign(response)
  })
}

watch(() => props.id, () => {
  loadOrder(props.id)
}, { immediate: true })
</script>
