<template>
  <header class="header">
    <div class="header-content">
      <div class="logo">
        <router-link to="/">
          <img alt="Белая кисть" src="@/assets/logo.png" width="400" height="40">
        </router-link>
      </div>
      <div class="right-part">
        <theme-switch />
        <div class="user-menu">
          <el-popover
            placement="bottom-end"
            trigger="hover"
          >
            <template #reference>
          <span class="user-button">
            <span class="user-title">{{ userTitle }}</span>
            <span class="role-title">{{ roleTitle }}</span>
          </span>
            </template>
            <el-link @click="logout">
              <el-icon><SwitchButton /></el-icon>
              Выйти
            </el-link>
          </el-popover>
        </div>
      </div>
    </div>
    <div class="header-menu">
      <header-menu />
    </div>
  </header>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from '@/store'
import { SwitchButton } from '@element-plus/icons-vue'
import HeaderMenu from '@/components/header/HeaderMenu.vue'
import ThemeSwitch from '@/components/header/ThemeSwitch.vue'

const store = useStore()

const userTitle = computed(() => store.state.user?.title || '')
const roleTitle = computed(() => store.state.role?.title || 'Без роли')
const logout = () => {
  store.dispatch('logout')
}

</script>

<style lang="scss">
.right-part {
  display: flex;
  align-items: center;

  .user-menu {
    margin-left: 15px;
    background: #3B393C;
    color: #fff;
  }
  .el-switch {
    --el-switch-off-color: #C4C4C4;
    .el-icon {
      color: #c4c4c4;
    }

    .el-switch__label.is-active {
      .el-icon {
        color: var(--el-color-primary);
      }
    }
  }
}
</style>
