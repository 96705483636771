<template>
  <div v-loading="state.loading">
    <el-row justify="end">
      <el-col :span="6">
        <el-input v-model="state.filter.search" placeholder="Поиск" @change="doFilter" clearable/>
      </el-col>
    </el-row>

    <el-table
      ref="tableRef"
      :data="tableData"
      empty-text="Ничего не нашлось"
      table-layout="auto"
      @sort-change="sortData"
    >
      <el-table-column label="Действия" header-align="center" align="center">
        <template #default="scope">
          <el-button type="success" :icon="EditPen" circle @click="$emit('editRecord', scope.row.id)"/>
          <el-popconfirm title="Удалить запись безвозвратно?" :width="220" @confirm="removeRecord(scope.row.id)">
            <template #reference>
              <el-button type="danger" :icon="Delete" circle />
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>

      <template v-for="col in columns" :key="col.prop">
        <el-table-column v-bind="col"/>
      </template>
    </el-table>
    <el-row justify="end">
      <el-pagination background layout="prev, pager, next" :total="state._meta.totalCount" :page-size="state._meta.perPage" v-model:current-page="state._meta.currentPage" :page-count="state._meta.pageCount" @change="doFilter" />
    </el-row>
  </div>
</template>

<script setup lang="ts">

import { computed, reactive, ref, watch } from 'vue'
import { iShop } from '@/types/models'
import { useRoute } from 'vue-router'
import { EditPen, Delete } from '@element-plus/icons-vue'
import { ElMessage, TableInstance } from 'element-plus'
import { TableColumnInstance } from 'element-plus/es/components/table'
import router from '@/router'
import restShops from '@/api/rest/restShops'

const route = useRoute()
defineEmits(['editRecord'])

interface iFilter {
  search: string
}

const tableRef = ref<TableInstance>()
const state = reactive<{
  items: Array<iShop>
  filter: iFilter
  loading: boolean
  _meta: {
    totalCount: number
    pageCount: number
    currentPage: number
    perPage: number
  }
}>({
  loading: true,
  filter: {
    search: route.query?.search as string || ''
  },
  items: [],
  _meta: {
    totalCount: 0,
    pageCount: 0,
    currentPage: Number(route.query.page) || 1,
    perPage: Number(route.query.perPage) || 20
  }
})

const columns: Array<TableColumnInstance['$props']> = [
  { prop: 'id', label: 'ID', sortable: true },
  { prop: 'title', label: 'Магазин' },
  { prop: 'full_title', label: '' },
  { prop: 'prefix', label: 'Префикс', headerAlign: 'center', align: 'center' }
]

const tableData = computed(() => state.items)

const load = () => {
  const queryParams = {
    params: {
      fields: ['id', 'title', 'full_title', 'prefix'].join(','),
      ...route.query
    }
  }

  state.loading = true

  restShops.get(queryParams)
    .then(response => {
      state.items = response.data.items
      state._meta = response.data._meta
    })
    .catch(error => {
      ElMessage.error('Непредвиденная ошибка')
      console.error(error)
    })
    .finally(() => {
      state.loading = false
    })
}
const doFilter = () => {
  const query = {
    ...state.filter,
    page: state._meta.currentPage,
    perPage: state._meta.perPage
  }

  router.push({ query })
}
const sortData = (sort: {order: string|null, prop: string}) => {
  const query = {
    ...route.params
  }

  if (sort.order === null) delete query.sort
  else if (sort.order === 'ascending') query.sort = sort.prop
  else if (sort.order === 'descending') query.sort = '-' + sort.prop

  router.push({ query })
}
const removeRecord = (recordId: number) => {
  state.loading = true

  restShops.remove(recordId)
    .then(() => {
      ElMessage.success('Удалено успешно')
    })
    .catch(error => {
      ElMessage.error('Непредвиденная ошибка')
      console.error(error)
    })
    .finally(() => {
      state.loading = false
      load()
    })
}

defineExpose({
  load
})

watch(() => route.query, () => {
  load()
}, { immediate: true })

</script>
