<template>
  <master-planning-index :hidden="formIsOpen" ref="indexRef" @edit-record="handlerEditRecord"/>
  <master-form v-if="formIsOpen" :record-id="selectedRecordId" @close="hideForm" @save="handlerSave"/>
</template>

<script setup lang="ts">

import MasterPlanningIndex from '@/components/masterPlanning/MasterPlanningIndex.vue'
import MasterForm from '@/components/masters/MasterForm.vue'
import { ref } from 'vue'
import LocationIndex from '@/components/locations/LocationIndex.vue'

const indexRef = ref<InstanceType<typeof LocationIndex>>(null)

const handlerSave = () => {
  indexRef.value.load()
}
const handlerEditRecord = (recordId: number) => {
  selectedRecordId.value = recordId
  showForm()
}
const showForm = () => {
  formIsOpen.value = true
}
const hideForm = () => {
  formIsOpen.value = false
  selectedRecordId.value = undefined
}

const formIsOpen = ref<boolean>(false)
const selectedRecordId = ref<number | undefined>(undefined)
</script>
