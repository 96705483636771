<template>
  <div class="summary-table-item summary-table summary-table-multiple">
    <el-table
      :data="tableData"
      :show-header="false"
      :row-class-name="tableRowClassName"
      empty-text="Выберите услуги"
    >
      <el-table-column prop="title" />
      <el-table-column prop="unit" width="100" />
      <el-table-column prop="price" width="100" />
      <el-table-column prop="qty" width="180" />
      <el-table-column prop="total" width="100" />
    </el-table>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { makeServiceTableData, TableServiceType, tableRowClassName } from '@/composables/useServicesTable'

const props = defineProps<{
  items: Array<TableServiceType>
}>()

const tableData = computed(() => makeServiceTableData(props.items))

</script>

<style lang="scss">
.summary-table-multiple {
  margin: 20px 0;
}
</style>
