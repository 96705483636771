import instance from '@/api/rest/instance'
import { AxiosPromise } from 'axios'
import { iUser } from '@/types/models'
import { iUserForm, Nullable } from '@/types/forms'
import { iRestCollection } from '@/types/responses'

export default {
  get: (params = {}): AxiosPromise<iRestCollection<iUser>> => instance.get('users', params),
  getById: (id: number, params = {}): AxiosPromise<iUser> => instance.get('users/' + id, params),
  save: (params: Nullable<iUserForm>) => {
    if (params.id) return instance.put('users/' + params.id, params)
    else return instance.post('users', params)
  },
  remove: (id: number) => instance.delete('users/' + id)
}
