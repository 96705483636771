<template>
  <div v-loading="state.loading">
    <el-row class="header-row" justify="space-between" align="middle">
      <h2>Магазины</h2>

      <el-button type="success" @click="addForm" :disabled="shops.length === formRefs.length || existEmptyForm">
        Добавить
      </el-button>
    </el-row>

    <el-scrollbar ref="scrollbarRef" height="650px" view-style="overflow-x: hidden; overflow-y: auto">
      <div class="content">
        <div
          class="item"
          v-for="(dataItem, index) in data"
          :key="dataItem.shop_id"
        >
          <el-row :gutter="10">
            <el-col :span="2">
              <el-row justify="center">
                <el-popconfirm title="Отвязать магазин?" :width="220" @confirm="unlinkShop(dataItem.shop_id)">
                  <template #reference>
                    <el-button type="danger" :icon="Minus" circle title="Отвязать" style="margin-top: 1em" />
                  </template>
                </el-popconfirm>
              </el-row>
            </el-col>
            <el-col :span="22">
              <partner-data-form
                ref="formRefs"
                :shops="shops"
                :free-shops="freeShops"
                :data-item="dataItem"
                :order-types="orderTypes"
                :errors="errors[dataItem.shop_id] || {}"
                @selected-shop="value => data[index].shop_id = value"
              />
            </el-col>
          </el-row>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script setup lang="ts">
import PartnerDataForm from '@/components/partners/PartnerDataForm.vue'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { iPartnerDataForm, iPartnerPartnerDataForm } from '@/types/forms'
import { iOrderType, iShop } from '@/types/models'
import restShops from '@/api/rest/restShops'
import { ElMessage, ElScrollbar } from 'element-plus'
import { Minus } from '@element-plus/icons-vue'
import restOrderTypes from '@/api/rest/restOrderTypes'
import { iPartnerDataFormErrors } from '@/types/formsErrors'

const props = withDefaults(
  defineProps<{
    data: iPartnerPartnerDataForm[]
    errors: {[key: string]: iPartnerDataFormErrors}
  }>(),
  {
    errors: () => ({})
  }
)

const scrollbarRef = ref<InstanceType<typeof ElScrollbar>>()
const formRefs = ref<InstanceType<typeof PartnerDataForm>[]>([])
const data = ref<(iPartnerDataForm & { orderTypes?: number[] })[]>([])
const shops = ref<iShop[]>([])
const orderTypes = ref<iOrderType[]>([])

const state = reactive({
  loading: false
})

const existEmptyForm = computed<boolean>(() => !!formRefs.value?.find(item => !item.state.form.shop_id) || false)
const freeShops = computed<iShop[]>(() => {
  const useShopIds = formRefs.value?.map(item => {
    return item.state.form.shop_id
  }).filter(Boolean) || []

  return shops.value.filter(shop => !useShopIds.includes(shop.id))
})

const addForm = () => {
  data.value.push({})
  scrollbarRef.value?.scrollTo(0, 0)
}
const unlinkShop = (shopId: number) => {
  data.value = data.value.filter(item => shopId !== item.shop_id)
}
const loadShops = () => restShops.list()
  .then(response => {
    shops.value = response.data.items
  })
  .catch(error => {
    ElMessage.error('Ошибка загрузки магазинов')
    console.error(error)
  })
const loadOrderTypes = () => restOrderTypes.get()
  .then(response => {
    orderTypes.value = response.data
  })
  .catch(error => {
    ElMessage.error('Ошибка загрузки типов')
    console.error(error)
  })

defineExpose({
  formRefs
})

watch(() => props.data, () => {
  const newData = [] as (iPartnerDataForm & { orderTypes?: number[] })[]

  props.data.forEach(item => {
    const foundExistsData = newData.find(newDataItem => newDataItem.shop_id === item.shop_id)

    if (!foundExistsData) {
      newData.push({
        shop_id: item.shop_id,
        orderTypes: [item.order_type_id]
      })
    } else {
      foundExistsData.orderTypes?.push(item.order_type_id)
    }
  })

  data.value = newData
}, { immediate: true })

onMounted(async () => {
  state.loading = true

  await loadShops()
  await loadOrderTypes()

  state.loading = false
})
</script>

<style scoped lang="scss">
  .header-row {
    border-bottom: 1px solid;
  }

  .content {
    display: flex;
    flex-direction: column-reverse;

    .item {
      margin-top: 1em;
      border-bottom: 1px dotted;
    }
  }
</style>
