<template>
  <el-form
    ref="formRef"
    label-position="top"
    :model="state.form"
    :rules="rules"
  >
    <el-form-item prop="shop_id" label="Магазин" :error="errors.shop_id">
      <el-select
        v-model="state.form.shop_id"
        filterable
        :disabled="state.form.shop_id"
      >
        <el-option
          v-for="option in shops"
          :key="option.id"
          :value="option.id"
          :label="option.title"
          :disabled="!freeShops.find(shop => shop.id === option.id) && option.id !== state.form.shop_id"
        />
      </el-select>
    </el-form-item>

    <el-row>
      <el-form-item
        v-for="orderType in orderTypes"
        :key="orderType.id"
        :label="orderType.title"
      >
        <el-checkbox :checked="state.form.orderTypes?.includes(orderType.id)" @change="value => handlerClickOrderType(value, orderType.id)" />
      </el-form-item>
    </el-row>
    <el-form-item prop="order_type_id" :error="errors.order_type_id" />
  </el-form>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import { iBaseFormState, iPartnerDataForm } from '@/types/forms'
import { iOrderType, iShop } from '@/types/models'
import { FormInstance, FormRules } from 'element-plus'
import { iPartnerDataFormErrors } from '@/types/formsErrors'
import { InternalRuleItem } from 'async-validator/dist-types/interface'

type StateInterface = iBaseFormState<iPartnerDataForm & { orderTypes: number[] }>

const emit = defineEmits(['selectedShop'])
const props = defineProps<{
  shops: iShop[]
  freeShops: iShop[]
  dataItem: iPartnerDataForm & { orderTypes?: number[] }
  orderTypes: iOrderType[]
  errors: iPartnerDataFormErrors
}>()

const formRef = ref<FormInstance>()

const state = reactive<StateInterface>({
  loading: false,
  form: {
    shop_id: props.dataItem.shop_id,
    orderTypes: props.dataItem.orderTypes || []
  },
  errors: {}
})

const rules = computed<FormRules<iPartnerDataForm>>(() => {
  const rules = {} as FormRules<iPartnerDataForm>

  const fields: Array<keyof iPartnerDataForm> = ['shop_id']

  fields.forEach((field) => {
    rules[field] = [
      { required: true, trigger: 'blur', message: 'Обязательно' }
    ]
  })

  rules.order_type_id = [
    { validator: validateOrderTypeId }
  ]

  return rules
})

const validateOrderTypeId = (rule: InternalRuleItem, value: undefined, callback: (error?: Error) => void) => {
  if (!state.form.orderTypes?.length) {
    callback(new Error('Необходим минимум один тип'))
  } else {
    callback()
  }
}
const handlerClickOrderType = (value: boolean, orderTypeId: number) => {
  state.form.orderTypes = state.form.orderTypes?.filter(id => id !== orderTypeId) || []

  if (value) {
    state.form.orderTypes.push(orderTypeId)
  }
}

defineExpose({
  formRef,
  state
})

watch(() => state.form.shop_id, () => emit('selectedShop', state.form.shop_id))

</script>

<style scoped>

</style>
