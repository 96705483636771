import instance, { fileInstance } from '@/api/instance'
import { iUserState, iUserToken } from '@/types/responses'
import { AxiosPromise } from 'axios'

export const setApiToken = (token: string) => {
  fileInstance.defaults.headers.common['X-Api-Key'] =
  instance.defaults.headers.common['X-Api-Key'] = token
}
export const clearApiToken = () => {
  delete instance.defaults.headers.common['X-Api-Key']
  delete fileInstance.defaults.headers.common['X-Api-Key']
}
export const userLogin = (data:{ login: string, password: string }): AxiosPromise<iUserToken> => instance.post('auth/login', data)
export const loadUserState = (): AxiosPromise<iUserState> => instance.get('auth/state')
