import axios from 'axios'
import instance, { instanceConfig, domain } from '@/api/instance'

const restInstance = axios.create({
  ...instanceConfig,
  baseURL: domain + '/api/rest/'
})

restInstance.defaults.headers = instance.defaults.headers

export default restInstance
