export const permissions = {
  access_order_price_fields: 'Устанавливать цены в заказах',
  order_create: 'Создание заявок',
  call_update: 'Редактирование созвона',
  call_delete: 'Удаление созвона',
  history_view_master: 'Показывать мастера в истории',
  order_any_assemble_date: 'Указание любой даты сборки',
  shops_partners: 'Доступны все магазины партнёра',
  partner_shop_type: 'партнеры того же магазина',
  partner_all: 'Все партнеры',
  order_manual_prices: 'Ручное указание цен',
  order_select_type: 'Принудительный выбор типа заявки',
  order_inner_notes: 'Редактирование вн примечания',
  order_flags: 'Установка отметок',
  orders_page: 'Страница заявок',
  master_planning_page: 'Мастера - планирование',
  statistic: 'Статистика',
  masters_page: 'Мастера',
  roles: 'Роли',
  debtors: 'Должники'
}

export type PermissionType = keyof typeof permissions
export type PermissionCanFunctionType = (permission: PermissionType) => boolean
