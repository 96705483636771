<template>
  <el-button v-if="hasReturnUrl" @click="handleClose">
    Назад
  </el-button>

  <order-update-form v-if="orderId" :id="orderId" />
  <order-create-form v-else />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import OrderCreateForm from '@/components/orders/OrderCreateForm.vue'
import OrderUpdateForm from '@/components/orders/OrderUpdateForm.vue'

const route = useRoute()
const router = useRouter()

const orderId = computed(() => (route.params.id as unknown as number|null) || undefined)
const hasReturnUrl = computed(() => route.query.returnUrl !== null)

const handleClose = () => {
  router.push(route.query.returnUrl !== null
    ? {
        name: 'orders',
        query: Object.fromEntries(new URLSearchParams(route.query.returnUrl as string))
      }
    : {
        name: 'orders'
      })
}
</script>
