import moment from 'moment'

export default [
  {
    text: 'Сегодня',
    value: () => [new Date(), new Date()]
  },
  {
    text: 'Вчера',
    value: () => [moment().subtract(1, 'd').toDate(), moment().subtract(1, 'd').toDate()]
  },
  {
    text: 'Последние 7 дней',
    value: () => [moment().subtract(7, 'd').toDate(), new Date()]
  },
  {
    text: 'Последние 30 дней',
    value: () => [moment().subtract(30, 'd').toDate(), new Date()]
  },
  {
    text: '2я половина',
    value: () => [moment().subtract(1, 'month').date(16).toDate(), moment().subtract(1, 'month').endOf('month').toDate()]
  },
  {
    text: '1я половина',
    value: () => [moment().date(1).toDate(), moment().date(15).toDate()]
  },
  {
    text: 'Текущий месяц',
    value: () => [moment().date(1).toDate(), new Date()]
  },
  {
    text: 'Предыдущий месяц',
    value: () => [moment().subtract(1, 'month').date(1).toDate(), moment().subtract(1, 'month').endOf('month').toDate()]
  },
  {
    text: 'Без даты',
    value: () => [moment(0).toDate(), moment(0).toDate()]
  }
]
