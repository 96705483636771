<template>
  <el-form
    v-loading="state.loading"
    ref="formRef"
    :model="state.form"
    @submit.prevent="handleSubmit"
  >
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item prop="call_date" label="Дата и время обзвона" :error="state.errors.call_date">
          <el-date-picker
            v-model="state.form.call_date"
            format="DD.MM.YYYY HH:mm:ss"
            :editable="false"
            type="datetime"
            value-format="YYYY-MM-DD HH:mm:ss"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="rating" label="Общая оценка" :error="state.errors.rating">
          <el-select v-model="state.form.rating">
            <el-option label="-" :value="0" />
            <el-option v-for="i in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" :key="i" :value="i" :label="i" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item prop="note" label="комментарии" :error="state.errors.note">
      <el-input type="textarea" v-model="state.form.note" />
    </el-form-item>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="is_time">
          <el-checkbox v-model="state.form.is_time" label="Ссылка?" :false-value="0" :true-value="1" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="is_quality">
          <el-checkbox v-model="state.form.is_quality" label="Качество?" :false-value="0" :true-value="1" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="is_polite">
          <el-checkbox v-model="state.form.is_polite" label="Вежливость?" :false-value="0" :true-value="1" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="is_extra_work">
          <el-checkbox v-model="state.form.is_extra_work" label="Предложение доп работ?" :false-value="0" :true-value="1" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item prop="extra_works" label="Список доп работ" :error="state.errors.extra_works">
      <el-input type="textarea" v-model="state.form.extra_works" />
    </el-form-item>
    <el-form-item prop="extra_price" label="Сумма доп работ" :error="state.errors.extra_price">
      <el-input v-model="state.form.extra_price" />
    </el-form-item>

    <el-button type="success" native-type="submit">Сохранить</el-button>
  </el-form>
</template>

<script lang="ts" setup>

import { iBaseFormStateNonNull, iCallForm } from '@/types/forms'
import { reactive, ref } from 'vue'
import { FormInstance } from 'element-plus'
import moment from 'moment'
import { createCall, updateCall } from '@/api/rest/restCalls'
import requestWrapper from '@/utils/requestWrapper'
import { iOrderCall } from '@/types/models'

interface iState extends iBaseFormStateNonNull<iCallForm> {
  id: number | undefined
  orderId: number
}

const emit = defineEmits(['save'])

const formRef = ref<FormInstance>()

const state = reactive<iState>({
  id: undefined,
  loading: false,
  orderId: 0,
  form: {
    call_date: moment().format('YYYY-MM-DD HH:mm:ss'),
    rating: 0,
    is_time: 0,
    is_quality: 0,
    is_polite: 0,
    is_extra_work: 0,
    extra_works: '',
    extra_price: 0,
    note: ''
  },
  errors: {}
})

const clear = () => {
  formRef.value?.resetFields()
  formRef.value?.clearValidate()
  state.id = undefined
}

const handleSubmit = () => {
  formRef.value?.validate(async valid => {
    if (valid) {
      state.loading = true
      const response = await requestWrapper(state.id ? updateCall(state.id, state.form) : createCall(state.orderId, state.form), state)
      state.loading = false
      if (response) {
        clear()
        emit('save', response)
      }
    }
  })
}

const load = (data: iOrderCall) => {
  const result: {[key: string]: unknown} = {}
  Object.keys(state.form).forEach((key) => {
    result[key] = data[key as keyof iCallForm]
  })
  Object.assign(state.form, result)
  state.id = data.id
  state.orderId = data.order_id
}

const create = (id: number) => {
  clear()
  state.orderId = id
}

defineExpose({
  clear,
  create,
  load
})

</script>
