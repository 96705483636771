<template>
  <div class="chart-container">
    <Line :data="chartData" :options="chartOptions" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement, ChartData, TimeScale, ChartOptions
} from 'chart.js'
import moment from 'moment'
import 'chartjs-adapter-moment'
import { iChartItem } from '@/types/models'

// Register necessary Chart.js components
ChartJS.register(Title, Tooltip, LineElement, CategoryScale, LinearScale, PointElement, TimeScale)

const props = defineProps<{
  data: iChartItem[]
}>()

const chartData = computed<ChartData<'line'>>(() => ({
  labels: props.data.map((item) => moment(item.date, 'YYYY-MM-DD').toDate()),
  datasets: [
    {
      label: 'Кол-во заявок',
      data: props.data.map((item) => item.value),
      fill: 'start',
      borderColor: 'rgba(75, 192, 192, 1)',
      backgroundColor: 'rgba(75, 192, 192, 0.4)'
    }
  ]
}))

const chartOptions = ref<ChartOptions<'line'>>({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        tooltipFormat: 'DD MMM',
        displayFormats: {
          day: 'DD MMM'
        }
      },
      title: {
        display: true,
        text: 'Дата оформления заказа'
      }
    },
    y: {
      title: {
        display: true,
        text: 'Кол-во заявок'
      },
      ticks: {
        callback: (value) => {
          // Отображаем только целые числа
          if (Number.isInteger(value)) {
            return value
          }
          return null
        }
      }
    }
  }
})
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 400px;
}
</style>
