import { iHttpException, iValidationErrorResponse } from '@/types/api'

export class ErrorException implements iHttpException {
  code: number
  message: string

  constructor (message: string, code: number) {
    this.message = message
    this.code = code
  }
}

export class ValidationException implements iHttpException {
  code = 422
  errors: iValidationErrorResponse

  constructor (errors: iValidationErrorResponse) {
    this.errors = errors
  }
}

export class CredentialsException implements iHttpException {
  code = 401
}
