import { reactive } from 'vue'
import { ElNotification } from 'element-plus'
import { AxiosError } from 'axios'
import { useStore } from '@/store'

const store = useStore()

export type iDataRequest = {
  pending: boolean
}

const useDataRequest = () => {
  type iFailOptions = {
    message?: string
    showMessage: boolean
  }

  const state = reactive<iDataRequest>({
    pending: false
  })

  return {
    state,

    start () {
      state.pending = true
    },
    end () {
      state.pending = false
    },
    success () {
      state.pending = false
    },
    fail (options: iFailOptions = {} as iFailOptions) {
      state.pending = false

      if (options.showMessage && options.message) {
        ElNotification({
          title: 'Ошибка',
          message: options.message,
          type: 'error'
        })
      }
    },
    async tryRequest (callback: () => void): Promise<void> {
      try {
        this.start()
        await callback()
        this.success()
      } catch (e: unknown) {
        if (e instanceof AxiosError && e.response) {
          if (e.response.status >= 500) {
            this.fail({ message: 'Серверная ошибка', showMessage: true })
          } else {
            switch (e.response.status) {
              case 401:
                await store.dispatch('logout')
                this.end()
                break
              case 403:
                this.fail({ message: 'Действие запрещено', showMessage: true })
                break
              case 404:
                this.fail({ message: 'Объект не найден', showMessage: true })
                break
              case 409:
                this.fail({ message: e.response.data.error, showMessage: true })
                break
              default:
                this.fail({ message: 'Неправильные данные', showMessage: true })
            }
          }
        } else {
          throw e
        }
      }
    }
  }
}

export default useDataRequest
