import { AxiosResponse } from 'axios'

export default async (axiosResponse: AxiosResponse<Blob>, title: string) => {
  const href = URL.createObjectURL(axiosResponse.data)
  const link = document.createElement('a')

  link.href = href
  link.setAttribute('download', title)
  link.click()
  URL.revokeObjectURL(href)
}
