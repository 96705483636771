<template>
  <el-form
    ref="formRef"
    label-position="top"
    :model="state.form"
    :rules="state.rules"
  >
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item label="Дата заказа" prop="date_order" :error="props.errors.date_order">
          <el-date-picker
            v-model="state.form.date_order"
            value-format="YYYY-MM-DD"
            format="DD.MM.YYYY"
            disabled
          />
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item label="Дата ориент." prop="date_expected" :error="props.errors.date_expected">
          <el-date-picker
            v-model="state.form.date_expected"
            value-format="YYYY-MM-DD"
            format="DD.MM.YYYY"
          />
        </el-form-item>
      </el-col>
      <el-col :span="2">
        <el-form-item label="Б/Д" prop="ignore_expected_date">
          <el-checkbox v-model="state.form.ignore_expected_date" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="date_assemble" label="Дата сборки" :error="props.errors.date_assemble">
          <el-date-picker
            v-model="state.form.date_assemble"
            value-format="YYYY-MM-DD"
            format="DD.MM.YYYY"
          />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="date_call" label="Дата созвона" :error="props.errors.date_call">
          <el-date-picker
            v-model="state.form.date_call"
            value-format="YYYY-MM-DD"
            format="DD.MM.YYYY"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="3">
        <el-form-item prop="is_urgent" label="Срочность">
          <el-checkbox v-model="state.form.is_urgent" />
        </el-form-item>
      </el-col>
      <el-col :span="3">
        <el-form-item prop="is_lying" label="Ложный выезд">
          <el-checkbox v-model="state.form.is_lying" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item prop="shop_id" label="Магазин" :error="props.errors.shop_id">
          <el-input v-if="shops.length === 1" type="text" :model-value="shops[0].title" disabled />
          <el-select
            v-else
            v-model="state.form.shop_id"
            filterable
            clearable
            :disabled="!isNew"
          >
            <el-option
              v-for="option in shops"
              :key="option.id"
              :value="option.id"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="12">
        <el-form-item label="Номер">
          <el-input v-model="state.form.number" :placeholder="state.shopSettings.expectedNumber" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="customer" label="Клиент (ФИО)" :error="props.errors.customer">
          <el-autocomplete
            v-model="state.form.customer"
            :fetch-suggestions="queryCustomer"
            @select="handleCustomerSelect"
          />
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="phone" label="Телефон" :error="props.errors.phone">
          <el-input v-model="state.form.phone" />
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="phone2" label="Телефон 2" :error="props.errors.phone2">
          <el-input v-model="state.form.phone2" />
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="email" label="E-Mail" :error="props.errors.email">
          <el-input v-model="state.form.email" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="address_district" label="Район" :error="props.errors.address_district">
          <el-input v-model="state.form.address_district" />
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="address_city" label="Город" :error="props.errors.address_city">
          <el-input v-model="state.form.address_city" />
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="address_street" label="Улица" :error="props.errors.address_street">
          <el-input v-model="state.form.address_street" />
        </el-form-item>
      </el-col>

      <el-col :span="2">
        <el-form-item prop="address_house" label="Дом" :error="props.errors.address_house">
          <el-input v-model="state.form.address_house" />
        </el-form-item>
      </el-col>

      <el-col :span="2">
        <el-form-item prop="address_building" label="Корпус" :error="props.errors.address_building">
          <el-input v-model="state.form.address_building" />
        </el-form-item>
      </el-col>

      <el-col :span="2">
        <el-form-item prop="address_room" label="Квартира" :error="props.errors.address_room">
          <el-input v-model="state.form.address_room" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item prop="address_extra" label="Адрес доп" :error="props.errors.address_extra">
          <el-input v-model="state.form.address_extra" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item prop="salesman" label="Продавец" :error="props.errors.salesman">
          <el-input v-model="state.form.salesman" />
        </el-form-item>
      </el-col>
    </el-row>

  </el-form>
</template>

<script lang="ts" setup>
import { iBaseFormState, iOrderForm } from '@/types/forms'
import { reactive, ref, computed, watch } from 'vue'
import moment from 'moment/moment'
import store from '@/store'
import { ElNotification, FormRules } from 'element-plus'
import { iAddress, iCustomerSuggestion, iOrder, iShopSettings } from '@/types/models'
import { getCustomerSuggestions } from '@/api/orders'
import { pickAndAssign } from '@/utils/object'
import { formatPhone } from '@/utils/format'

type iState = iBaseFormState<iOrderForm> & {
  shopSettings: iShopSettings
}

const shops = computed(() => store.state.shops)

const props = defineProps<{
  errors: object
}>()
const state = reactive<iState>({
  shopSettings: {
    expectedNumber: null
  },
  form: {
    id: null,
    number: '',
    date_order: moment().format('YYYY-MM-DD'),
    date_expected: '',
    date_assemble: '',
    date_call: '',
    ignore_expected_date: false,
    is_urgent: false,
    is_lying: false,
    shop_id: shops.value.length === 1 ? shops.value[0].id : null,
    customer: '',
    phone: '',
    phone2: '',
    email: '',
    address_district: '',
    address_extra: '',
    address_room: '',
    address_building: '',
    address_city: '',
    address_house: '',
    address_street: '',
    salesman: ''
  },
  rules: (() => {
    const rules = {} as FormRules<iOrder>
    const phonePatternRule = { pattern: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/, trigger: 'blur', message: 'Укажите телефон полностью' }
    const requiredBlurRule = { required: true, trigger: 'blur', message: 'Обязательно' }
    const fields:Array<keyof iOrder> = ['date_order', 'customer', 'address_city', 'address_street', 'address_house']

    fields.forEach((field) => {
      rules[field] = [
        requiredBlurRule
      ]
    })

    rules.shop_id = [
      { required: true, trigger: 'change', message: 'Обязательно' }
    ]

    rules.email = [
      { type: 'email', trigger: 'blur', message: 'Почта указана неправильно' }
    ]

    rules.phone = [
      phonePatternRule,
      requiredBlurRule
    ]
    rules.phone2 = [
      phonePatternRule
    ]

    return rules
  })()
})

const emit = defineEmits(['change-shop-id'])

const formRef = ref()

const isNew = computed(() => state.form.id === null)

type iCustomerSuggestionItem = {
  value: string
  item: iCustomerSuggestion
}

const queryCustomer = (queryString: string, cb: (result: iCustomerSuggestionItem[]) => void) => {
  const emptyValue: iCustomerSuggestionItem[] = []
  if (queryString.length > 0) {
    getCustomerSuggestions(queryString).then(result => {
      const response = result.data
      const items: iCustomerSuggestionItem[] = response.map(item => ({
        value: `${item.customer} [${item.phone}]`,
        item
      }))
      cb(items)
    }).catch(() => {
      ElNotification({
        title: 'Ошибка',
        message: 'Ошибка при загрузке списка заказчиков',
        type: 'error'
      })
      cb(emptyValue)
    })
  } else {
    cb(emptyValue)
  }
}

const address = computed<iAddress>(() => ({
  address_district: state.form.address_district || '',
  address_extra: state.form.address_extra || '',
  address_room: state.form.address_room || '',
  address_building: state.form.address_building || '',
  address_city: state.form.address_city || '',
  address_house: state.form.address_house || '',
  address_street: state.form.address_street || ''
}))

const handleCustomerSelect = (item: iCustomerSuggestionItem) => {
  Object.assign(state.form, item.item)
}

const assign = (params: iOrder) => {
  pickAndAssign(state.form, params)
}

const formData = computed(() => ({ ...state.form }))

defineExpose({
  assign,
  formData,
  formRef,
  address
})

watch(() => [state.form.phone, state.form.phone2], () => {
  state.form.phone = formatPhone(state.form.phone)
  state.form.phone2 = formatPhone(state.form.phone2)
})

watch(() => state.form.shop_id, async () => {
  emit('change-shop-id', state.form.shop_id)
})

</script>
